@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wxapp-main-header {
  &.v-toolbar.v-app-bar {
    z-index: 8;
    box-shadow: var(--box-shadow-small-elevation);

    @media ($wx-md-min) {
      z-index: 1; // required for display hover wxapp-main-page
      left: auto !important; // flex aligned to right
      max-width: 320px; // constrain the width so wxapp-main-page content stays clickable
      border-bottom-left-radius: var(--border-radius-lg); // preventing design for z-index
      box-shadow: none;
    }
    @media ($wx-lg-min) {
      max-width: 375px; // allow factory-selector full width on large viewport
    }
    @media ($wx-xl-min) {
      margin-top: 10px !important; // vertically align with `wxapp-logo--drawer`
    }

    ::v-deep .v-toolbar__content {
      // constrain the height to ($vuetify.application.top value of) 56px
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

// gutter between right buttons
::v-deep .v-btn {
  margin-right: calc(var(--grid-gutter) / 2);

  &:last-child {
    margin-right: 0;
  }
}

.wxapp-logo--mobile.v-btn {
  // local vars
  $logo_iconSize: 34px;
  $logo_padding: 8px;
  // style
  height: ($logo_iconSize + $logo_padding);
  width: ($logo_iconSize + $logo_padding);
  min-width: $logo_iconSize;
  margin: 0 3em 0 0;
  padding: 0;
  border-radius: $logo_padding;
  background: url("~@/assets/Tilelytics-Logo-Square-Light.svg") center center no-repeat;
  background-size: $logo_iconSize;

  @media ($wx-xs-max) {
    margin-right: 2em; // allign to center
  }

  // initially the default image (above) was `Tilelytics-Logo-Square-Dark.svg`
  /* &.theme--light {
    background-image: url("~@/assets/Tilelytics-Logo-Square-Light.svg");
  } */
}
