@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.activator-btn {
  background-color: var(--color-control-background-theme);
}

// Vuetify menu
.v-menu__content {
  box-shadow: var(--box-shadow-high-elevation);
}
.container {
  padding-top: 20px;
  width: 270px;
  background-color: var(--color-flat-panel-theme);
}

// Vuetify list
.v-sheet.v-list {
  display: flex;
  width: 100%;
  padding: 20px 0;
  background-color: transparent;
  box-shadow: none;
}
.v-list--nav {
  .v-list-item {
    display: inline-flex;
    width: auto;
    padding: 0;
    margin-inline: 5%;

    &:not(:last-child) {
      margin-bottom: 0;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    // <a>
    .link-item {
      display: block;
      margin-inline: auto;
      padding: 5px;
      color: var(--color-text-theme);
      text-decoration: none;

      &::before {
        content: "";
        position: absolute;
        z-index: 0;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: var(--color-border-theme);
        pointer-events: none;
        transition: var(--smooth-transition);
        transition-property: opacity, background-color;
        opacity: 0;
      }

      .logo-img,
      .brand-string {
        display: block;
        position: relative;
        z-index: 1;
        text-align: center;
      }

      .logo-img {
        width: 34px;
        height: 34px;
        margin-inline: auto;
        margin-bottom: 10px;
        background: White no-repeat center center;
        border-radius: var(--border-radius-sm);

        &.tilelytics {
          background-image: url("~@/assets/wx-logos/tilelytics_light.svg");
          background-size: 80%;
        }
        &.tileplus {
          background-image: url("~@/assets/wx-logos/tileplus_light.svg");
          background-size: 80%;
        }
        &.worxspace {
          background-image: url("~@/assets/wx-logos/worxspace_light.svg");
          background-size: 90%;
        }
      }

      // Events
      &:not(.disabled) {
        &:hover,
        &:focus {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}
