@use "sass:map";
@import "_values";

/** -----------------------
 * Responsive Breakpoints
 * mapping vars from _values.scss
 */
$wx-breakpoint-sm: map.get($wxBreakpoint-pixelValues, "smBreakpoint");
$wx-breakpoint-md: map.get($wxBreakpoint-pixelValues, "mdBreakpoint");
$wx-breakpoint-lg: map.get($wxBreakpoint-pixelValues, "lgBreakpoint");
$wx-breakpoint-xl: map.get($wxBreakpoint-pixelValues, "xlBreakpoint");

// arbitrary value
$wx-breakpoint-phone-vertical: 420px;

/** -----------------------
 * Media-queries to be used in Single File Components
 * ex: `@media ($wx-min-sm) {}`
 */

// Maximum
$wx-xs-max: "max-width: " + ($wx-breakpoint-sm - 1px); // ↤ isMobilePhone ONLY
// Minimum
$wx-sm-min: "min-width: " + $wx-breakpoint-sm; // ↥ isMobile (tablets included)
$wx-md-min: "min-width: " + $wx-breakpoint-md; // ↧ !isMobile (desktops)
$wx-lg-min: "min-width: " + $wx-breakpoint-lg;
$wx-xl-min: "min-width: " + $wx-breakpoint-xl;
// Based on `$vuetify.breakpoint.mobile` TRUE/FALSE
$wx-isMobile: "max-width: " + ($wx-breakpoint-md - 1px); // ↦↤ TRUE is mobile phones or tablets
$wx-isNotMobile: "min-width: " + $wx-breakpoint-md; // ↤↦ FALSE is wider then mobile tablets

// Gutter values (Vuetify var = $grid-gutter)
$wx-grid-gutter-xs: 12px;
$wx-grid-gutter-sm: 20px;
$wx-grid-gutter-md: 24px;
$wx-grid-gutter-lg: 32px;
$wx-grid-gutter-xl: 40px;

// Targetted devices width
$wx-max-width-phone-vertical: (($wx-breakpoint-phone-vertical - 1px) - ($wx-grid-gutter-xs * 2));
$wx-min-width-phone-vertical: ($wx-breakpoint-phone-vertical - ($wx-grid-gutter-xs * 2));
$wx-min-width-tablet-vertical: (map.get($wxBreakpoint-pixelValues, "smBreakpoint") - ($wx-grid-gutter-sm * 2));

/** -----------------------
 * Body font-sizes values
 */
$wx-body-font-size-xs: 0.875rem;
$wx-body-font-size-sm: 1rem;
$wx-body-font-size-xl: 1.25rem;

/** -----------------------
 * Vuetify js variable `$vuetify.application.top` is responsible 
 * for the `height="56px"` on the `v-toolbar__content` in MainHeader.
 * This custom var is a hack to get it in our scss styles
 */
$wx_vuetify_application_top: 56px;
