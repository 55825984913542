@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.activator-btn {
  background-color: var(--color-control-background-theme);
}

// Vuetify's <v-menu> wrapper
.v-menu__content {
  box-shadow: var(--box-shadow-high-elevation);
}
.container {
  padding-top: 20px;
  width: 300px;
  background-color: var(--color-flat-panel-theme);
}

.v-btn {
  font-size: 0.8125rem;

  // termsOfService
  &.v-btn--text {
    font-weight: 400; /* Regular */
    color: var(--color-text-subtle-theme) !important;
    text-decoration: underline;
  }
}
.v-item-group.v-btn-toggle {
  width: 100%;
  & > .v-btn {
    flex: 1;
    // fix a vuetify glitch where after 2nd click an active button looks darker.
    opacity: 1;
    &.v-btn--active {
      &:hover::before,
      &::before {
        opacity: 0;
      }
    }
    // when active color is primary and UX = disable
    &.primary {
      pointer-events: none;
      cursor: auto;
    }
  }
}

.user-profile {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
