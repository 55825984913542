@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
/**
 * TYPOGRAPHY
 * font-weight values match Google Fonts: (400, Regular), (500, Medium), (700, Bold)
 */

h1,
.wx-typo-h1 {
  font-size: var(--font-size-h1);
  line-height: 1.16666666;
  font-weight: 700; /* Bold */
}

h2,
.wx-typo-h2 {
  font-size: var(--font-size-h2);
  line-height: 1.2;
  font-weight: 700; /* Bold */
}

h3,
.wx-typo-h3 {
  font-size: var(--font-size-h3);
  line-height: 1.2;
  font-weight: 700; /* Bold */
}

h4 {
  font-size: var(--font-size-norm);
  font-weight: 700; /* Bold */
}

h5 {
  font-size: 0.8125rem; // 13px, NOT responsive
  font-weight: 500; /* Medium */
}

body,
.wx-typo-norm {
  font-size: var(--font-size-norm);
  font-weight: 400; /* Regular */
}

.wx-typo-sm {
  font-size: var(--font-size-sm);
  line-height: 1.1;
  font-weight: 400; /* Regular */
}

.wx-typo-xs {
  font-size: var(--font-size-xs);
  line-height: 1.1;
  font-weight: 400; /* Regular */
}
