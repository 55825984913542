@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-application {
  &#wxapp {
    // gradient is set within layouts
    background-color: var(--color-base-theme);
  }

  // isMobile
  .wxapp-top-alert {
    position: fixed;
    top: 55px;
    z-index: 999;
  }

  // !isMobile
  @media ($wx-md-min) {
    .wxapp-top-alert {
      top: 0;
    }
  }

  /** - - - - -
   * Presenter
   */
  &.presenter {
    padding: var(--grid-gutter);
    background-color: var(--color-pu-current-state);
    position: relative;
    transition: background-color 0.3s linear;

    &:after {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: var(--grid-gutter) solid var(--color-pu-current-state);
      z-index: 6;
      pointer-events: none;
      transition: border-color 0.3s linear;
    }

    ::v-deep .v-application--wrap {
      min-height: calc(100vh - var(--grid-gutter) * 2);
    }

    ::v-deep .v-navigation-drawer {
      max-height: calc(100% - var(--grid-gutter) * 2) !important;
      left: var(--grid-gutter) !important;
      top: var(--grid-gutter) !important;
    }
  }
}

.v-list {
  .alert-message {
    color: var(--color-flat-panel-theme);
  }
}
